.timeline-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
  }

  h1 {
    color: #0489ae;
    font-size: 48px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;}
  
  .timeline-container .MuiTimeline-root {
    width: 80%; /* Adjust width as needed */
    max-width: 1200px; /* Adjust max-width as needed */
  }

  .timeline-container .MuiTypography-root {
    font-size: 20px; /* Adjust font size as needed */
  }
  
  .timeline-container .MuiSvgIcon-root {
    font-size: 32px; /* Adjust icon size as needed */
  }
  
  .timeline-container .MuiTimelineContent-root {
    padding: 16px; /* Adjust padding as needed */
  }

  .timeline-container .MuiTimelineItem-root {
    animation: slideIn 1s ease forwards;
    animation-delay: 3s;

    opacity: 0;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 20%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  


    @media screen and (max-width: 1200px) {
      .tags,
    
      h1 {font-size: medium;}
    

    
      .home-page .flat-button {
        float: none;
        display: table;
        margin: 20px auto 0 auto;
        width: 124px;
      }
  
      .img{height: 5rem; width:5rem;}
      .image-container img {
        width: 100%; /* Make the image fill the container width */
        height: auto; /* Maintain the aspect ratio of the image */
      }
    
      .logo-container {
        position: relative;
        width: 100px;
        height: auto;
        top: 50px;
        right: 0;
        box-sizing: border-box;
        margin: auto;
        left: 0;
      }
    
      .logo-container svg {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }

      .timeline-container .MuiTypography-root {
        font-size: 15px; /* Adjust font size as needed */
      }
      
      .timeline-container .MuiSvgIcon-root {
        font-size: 25px; /* Adjust icon size as needed */
      }
      
    }