.page-container{
    display: flex;
    flex-direction: column; /* Align items horizontally */
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}
.top-row{
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 95%;
    height: 50%;
    margin-top: 2%;
    .radial{
        width: 40%;
        height:100%
    }
    .stacked{
        width: 60%;
        height:100%
    }
}
.bottom-row{
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 95%;
    height: 66%;
    
    .line{
        width: 100%;
        height:100%
    }
}
.icon{
    top:0;
    margin-top:5%
  }
  .header{
    margin-left: 5%;
    margin-bottom: 2%;
    max-height: 5%;
  
  h1 {
    color: #0489ae;
    font-size: 56px;
    line-height: 53px;
    font-family: 'Coolvetica';
    font-weight: 400;
    }}