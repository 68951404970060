.page-container {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 100%;
    height: 100vh;
  }
  .contents{display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .chart-container {
    width: 90%; /* Adjust the width as needed */
    height: 95%; /* Adjust the height as needed */
    margin:2% 5% 5% 5%;
    min-height: 95%;
    z-index: 1;
  }
  
  .grid-container {
    width: 35%; /* Adjust the width as needed */
    height: 77%; /* Adjust the height as needed */
    overflow: auto; /* Ensure the grid is scrollable if needed */
    margin-right:4%;
    justify-content: center;
    align-items: center;
  }
  
  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    display: none;
    font-size: 12px;
    z-index: 10;
  }
   .MuiDataGrid-main.css-3eek4p-MuiDataGrid-main{
    background:white ;
  }
  
 
  .ag-theme-alpine .ag-cell {
    padding: 5px; /* Remove extra padding */
  }
  
  .header{
    margin-left: 5%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 15%;

    .card{
        max-height: 20%;
        .card-content{
            height: 100%;
        }
    }
  
  h1 {
    color: #0489ae;
    font-size: 56px;
    line-height: 53px;
    font-family: 'Coolvetica';
    font-weight: 400;

 }}

    .fade-in {
      opacity: 0;
      animation: fadeInAnimation 5s forwards; /* Adjust duration as needed */
    }
    
    @keyframes fadeInAnimation {
      to {
        opacity: 1;
      }
    }
    .js-plotly-plot .plot-container .svg-container .main-svg .geolayer .layer.bg rect {
        fill: transparent !important; /* Ensure the background is set correctly */
    }
   