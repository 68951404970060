.page-container {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 100%;
    height: 100vh;
  }
  .contents{display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .chart-container {
    width: 100%; /* Adjust the width as needed */
    height: 95%; /* Adjust the height as needed */
    margin:2% 2% 2% 2%;
    min-height: 95%;
  }
  
  .icon-container {
    width: 4%; /* Adjust the width as needed */
    height: 80%; /* Adjust the height as needed */
    overflow: auto; /* Ensure the grid is scrollable if needed */
    margin-right:2%;
    justify-content: center;
    align-items: flex-start;
  }
  
  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    display: none;
    font-size: 12px;
    z-index: 10;
  }
  
 
  .ag-theme-alpine .ag-cell {
    padding: 5px; /* Remove extra padding */
  }
  
  .header{
    margin-left: 5%;
    margin-bottom: 2%;
  
  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    font-family: 'Coolvetica';
    font-weight: 400;
    }}
    .info{
      color: #0489ae; 
      font-size: 3rem !important;
      cursor: pointer;
    }

    .fade-in {
      opacity: 0;
      animation: fadeInAnimation 5s forwards; /* Adjust duration as needed */
    }
    
    @keyframes fadeInAnimation {
      to {
        opacity: 1;
      }
    }