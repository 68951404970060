.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height to center vertically */
    width: 100%;
  }
  
  swiper-container {
    width: 90%; /* Adjust as needed */
    max-width: 90%; /* Adjust as needed */
    height: 90%;
    margin-top:2%;
  }
  .box{
    margin-top: 2rem;
  }
 



swiper-slide {
   width: 100%;
   height: 100%;
}